@import '../../../../scss/theme-bootstrap';

.gnav-country-chooser-formatter {
  &--dropdown {
    .gnav-country-chooser-formatter--selectbox {
      display: inline-block;
    }
    .gnav-country-chooser-list {
      display: none;
    }
  }
  &--inline {
    .gnav-country-chooser-formatter--list {
      display: flex;
      @include breakpoint($landscape-up) {
        margin-#{$ldirection}: 3px;
      }
      li {
        padding-#{$rdirection}: 15px;
        margin: 4px 0;
        line-height: 1.1;
        @include breakpoint($landscape-up) {
          padding: 0 10px;
          line-height: 0.8;
          margin: inherit;
        }
        &:not(:last-child) {
          border-#{$rdirection}: 1px solid $color-silver;
        }
        &:last-child {
          padding-#{$ldirection}: 15px;
          @include breakpoint($landscape-up) {
            padding: 0 8px;
          }
        }
      }
      a {
        text-decoration: none;
      }
    }
    .gnav-country-chooser-selectbox {
      display: none;
    }
  }
}
